<template>
    <div>
        <b-card title="Hospitality">
            <b-card-text>Welcome to hospitality sistem</b-card-text>
        </b-card>
    </div>
</template>

<script>
    import { BCard, BCardText } from 'bootstrap-vue'

    export default {
        components: {
            BCard,
            BCardText
        }
    }
</script>

<style>

</style>
